<template>
  <div>
    <v-row>
      <v-col md="6" cols="12">
        <FormControl inputType="monthPicker" :inputValue.sync="salaryData.yearMonth" label="月份" :required="true" />
      </v-col>
      <v-col md="6" cols="12">
        <FormControl
          inputType="number"
          :inputValue.sync="salaryData.basicSalary"
          label="薪金"
          :required="true"
          :validateFloating="true"
          :inputFloatingOnly="true"
        />
      </v-col>
      <v-col md="6" cols="12">
        <FormControl
          inputType="number"
          :inputValue.sync="salaryData.commission"
          label="獎金"
          :required="true"
          :validateFloating="true"
          :inputFloatingOnly="true"
        />
      </v-col>
      <v-col md="6" cols="12">
        <FormControl
          inputType="number"
          :inputValue.sync="salaryData.otSalary"
          label="加班時數薪金"
          :required="true"
          :validateFloating="true"
          :inputFloatingOnly="true"
        />
      </v-col>
      <v-col md="6" cols="12">
        <FormControl
          inputType="select"
          :inputValue.sync="salaryData.salaryStatus"
          label="出糧狀態"
          :options="salaryStatusList"
          :required="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormControl from './form/FormControl.vue'
export default {
  name: 'SalaryField',
  components: { FormControl },
  props: {
    salaryData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    salary: {
      id: -1,
      yearMonth: '',
      basicSalary: '',
      commission: '',
      otSalary: '',
      salaryStatus: '',
    },
  }),
  computed: {
    salaryStatusList() {
      return this.$salaryStatusList
    },
  },
}
</script>
