<template>
  <v-container fluid>
    <PageHeaderSlot :showBottomLeftAndRightRow="true" :showBackButton="true" :isLoading="submitLoading || tableLoading">
    </PageHeaderSlot>

    <v-row class="mt-6">
      <v-col cols="12">
        <h3>{{ $validate.DataValid(routeStaffID) ? '薪金紀錄詳細' : '新增薪金紀錄' }}</h3>
      </v-col>
      <v-col cols="12">
        <v-form ref="salary-form">
          <v-card class="py-2 px-2 mb-10">
            <v-card-text>
              <v-row>
                <v-col md="6" cols="12">
                  <h3>員工資料</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="select"
                    :inputValue.sync="staff_id"
                    label="員工"
                    :options="selectStaffList"
                    :required="true"
                    :disabled="$validate.DataValid(routeStaffID)"
                    @selectChange="updateBasicSalary"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <template v-if="!$validate.DataValid(routeStaffID)">
            <v-card class="py-2 px-2">
              <v-card-text>
                <v-row class="mb-3">
                  <v-col md="6" cols="12">
                    <h3>出糧資料</h3>
                  </v-col>
                </v-row>
                <SalaryField :salaryData="selectSalaryData"></SalaryField>
              </v-card-text>
            </v-card>

            <v-btn
              depressed
              color="primary accent-4 mt-6"
              class="white--text"
              :loading="submitLoading"
              @click="addSalaryRecord"
            >
              儲存
            </v-btn>
          </template>

          <template v-else>
            <v-card class="py-2 px-2">
              <v-card-text>
                <v-row class="mb-3">
                  <v-col md="6" cols="12">
                    <h3>出糧紀錄</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <Datatable
                      :table-headers="tableHeaders"
                      :table-data="formData"
                      :page="tablePage"
                      :page-limit="tablePageLimit"
                      :page-limit-options="tablePageLimitOptions"
                      :item-total="formItemTotal"
                      :is-loading="tableLoading"
                      :disable-pagination="tableLoading"
                      @options-update="onTableChange"
                    >
                      <template v-slot:[`item.editSalary`]="{ item, index }">
                        <v-btn
                          text
                          small
                          color="primary"
                          @click="openSalaryDialog(index, item.id)"
                          :disabled="submitLoading || tableLoading"
                          >編輯</v-btn
                        >
                      </template>
                    </Datatable>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-form>

        <v-dialog :value="openDialog" persistent :max-width="800" eager>
          <v-form ref="edit-salary-form" @submit.prevent="updateSalaryRecord">
            <v-card class="px-5 py-3">
              <v-card-text class="d-flex justify-end pt-1 px-0">
                <v-btn icon @click.prevent="openDialog = false" :disabled="submitLoading || tableLoading">
                  <v-icon>{{ mdiClose }}</v-icon>
                </v-btn>
              </v-card-text>
              <v-card-text>
                <SalaryField :salaryData="selectSalaryData"></SalaryField>
              </v-card-text>
              <v-card-actions class="justify-center mt-3">
                <v-row>
                  <v-col cols="6">
                    <v-btn
                      color="indigo accent-4"
                      class="primary m-auto white--text"
                      block
                      x-large
                      type="submit"
                      depressed
                      :loading="submitLoading || tableLoading"
                      >儲存</v-btn
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      color="error"
                      class="primary m-auto white--text"
                      block
                      x-large
                      depressed
                      :loading="submitLoading || tableLoading"
                      @click.prevent="deleteDialog = true"
                      >刪除</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <CustomDialog
          :dialog.sync="deleteDialog"
          :text="'刪除薪金紀錄'"
          :deleteDescription="'你是否要刪除此薪金紀錄?'"
          @close="deleteSalaryRecord"
        ></CustomDialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormControl from '@/components/form/FormControl'
import SalaryField from '@/components/SalaryField.vue'
import Datatable from '@/components/Datatable.vue'
import { mdiClose } from '@mdi/js'
import CustomDialog from '@/components/CustomDialog.vue'

export default {
  name: 'AdminSalaryDetail',
  components: {
    FormControl,
    SalaryField,
    Datatable,
    CustomDialog,
  },
  data() {
    return {
      mdiClose,
      submitLoading: false,
      staff_id: '',
      staffList: [],

      tableHeaders: [
        { text: '月份', value: 'year_month' },
        { text: '薪金', value: 'basic_salary' },
        { text: '日薪', value: 'daily_salary' },
        { text: '獎金', value: 'commission' },
        { text: '無薪假日數', value: 'npl' },
        { text: '病假日數(超過4日)', value: 'sl' },
        { text: '加班時數(小時)', value: 'ot_count' },
        { text: '僱員MPF供款', value: 'mpf_employee' },
        { text: '僱主MPF供款', value: 'mpf_employer' },
        { text: '報銷費用', value: 'expense' },
        { text: '出糧狀態', value: 'salary_status' },
        { text: '總數', value: 'total' },
        {
          text: '',
          value: 'editSalary',
          sortable: false,
          align: 'end',
        },
      ],
      tableLoading: false,
      tablePage: 1,
      tablePageLimit: 20,
      tablePageLimitOptions: [20, 50, 100],
      formItemTotal: 0,
      formData: [],

      saveSalaryList: [],

      openDialog: false,
      dialogIndex: -1,

      selectSalaryData: {
        id: -1,
        yearMonth: '',
        basicSalary: '',
        commission: '',
        otSalary: '',
        salaryStatus: '',
      },

      deleteDialog: false,
    }
  },
  computed: {
    routeStaffID() {
      return this.$route.params.staff_id
    },
    selectStaffList() {
      return this.staffList.map(obj => {
        return { text: `${obj.name} (${obj.email})`, value: obj.id }
      })
    },
  },

  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog']),
    async getStaffList() {
      try {
        const payload = {
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_staff', payload)
        this.$func.log('-----Get Staff List-----')
        this.$func.log(result)

        this.staffList = result.data
      } catch (error) {
        this.$func.log('-----Get Staff fail-----')
        this.$func.log(error)

        let msg = ''

        if (error.data === 'no permission') {
          msg = '沒有權限'
        }

        if (this.$validate.DataValid(msg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: msg,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }
      }
    },
    async getSalaryList() {
      if (!this.tableLoading) {
        this.tableLoading = true

        let verifySalary = false
        let message = '資料不存在'

        try {
          const payload = {
            staff_id: parseInt(this.routeStaffID),
            verify_token: this.getAdminToken(),
            calculate_total: true,
          }

          const result = await this.$XHR.api('cms_get_salary_record_list', payload)
          this.$func.log('-----Get Salary Detail-----')
          this.$func.log(result)

          this.formItemTotal = result.total
          this.saveSalaryList = result.data

          const temp = []

          for (let i = 0; i < result.data.length; i++) {
            const resultData = result.data[i]

            const data = {
              id: resultData.id,
              year_month: resultData.year_month,
              basic_salary: `$${resultData.basic_salary}`,
              daily_salary: `$${resultData.daily_salary}`,
              commission: `$${resultData.commission}`,
              npl: `${resultData.no_pay_leave_count}`,
              sl: `${resultData.sick_leave_percent_day}`,
              ot_count: `${resultData.total_ot_count}`,
              mpf_employee: `$${resultData.mpf}`,
              mpf_employer: `$${resultData.mpf}`,
              expense: `$${resultData.expense}`,
              total: `$${resultData.total_salary}`,
              salary_status: resultData.salary_status,
            }

            temp.push(data)
          }

          this.formData = temp

          this.$store.dispatch('toggleLoadingPage', false)
        } catch (error) {
          this.$func.log('-----Get Salary Fail-----')
          this.$func.log(error)

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            verifySalary = true
            message = '沒有權限'
          } else {
            verifySalary = true
          }
        }

        if (verifySalary) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message,
            type: 'error',
            refresh: false,
            redirect: 'adminSalary',
          })
        }

        this.tableLoading = false
      }
    },
    updateBasicSalary() {
      const filterStaff = this.staffList.filter(obj => {
        return obj.id === parseInt(this.staff_id)
      })

      if (filterStaff.length > 0) {
        this.$set(this.selectSalaryData, 'basicSalary', filterStaff[0].salary)
      }
    },
    openSalaryDialog(index, id) {
      this.dialogIndex = index

      const item = this.saveSalaryList[index]

      this.$set(this.selectSalaryData, 'yearMonth', item.year_month)

      this.selectSalaryData = {
        id,
        yearMonth: item.year_month,
        basicSalary: item.basic_salary,
        commission: item.commission,
        otSalary: item.ot_salary,
        salaryStatus: item.salary_status,
      }

      this.openDialog = true
    },
    async addSalaryRecord() {
      if (!this.submitLoading) {
        this.submitLoading = true

        const validate = this.$refs['salary-form'].validate()

        if (!validate) {
          this.submitLoading = false
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }

        try {
          const payload = {
            verify_token: this.getAdminToken(),
            staff_id: this.staff_id,
            year_month: this.selectSalaryData.yearMonth,
            basic_salary: parseFloat(this.selectSalaryData.basicSalary),
            ot_salary: parseFloat(this.selectSalaryData.otSalary),
            commission: parseFloat(this.selectSalaryData.commission),
            salary_status: this.selectSalaryData.salaryStatus,
          }

          const result = await this.$XHR.api('cms_add_salary_record', payload)
          this.$func.log('--- add salary data ---')
          this.$func.log(result)

          this.setDialogMessage({
            message: '新增成功',
            isError: false,
            returnLink: { name: 'adminSalary' },
          })
          this.setShowDialog(true)
        } catch (error) {
          this.$func.log('--- add salary data fail ---')
          this.$func.log(error)

          let msg = '新增失敗'

          if (error.data === 'admin verification fail') {
            msg = ''
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          } else if (error.data === 'staff not found') {
            msg = '員工不存在'
          } else if (error.data === 'salary record exists') {
            msg = '此日期的出糧紀錄已存在'
          }

          this.submitLoading = false
          this.setDialogMessage({
            message: msg,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },
    async updateSalaryRecord() {
      if (!this.submitLoading) {
        this.submitLoading = true

        const validate = this.$refs['edit-salary-form'].validate()
        if (!validate) {
          this.submitLoading = false
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }

        try {
          const payload = {
            id: parseInt(this.selectSalaryData.id),
            verify_token: this.getAdminToken(),
            staff_id: parseInt(this.routeStaffID),
            year_month: this.selectSalaryData.yearMonth,
            basic_salary: parseFloat(this.selectSalaryData.basicSalary),
            commission: parseFloat(this.selectSalaryData.commission),
            ot_salary: parseFloat(this.selectSalaryData.otSalary),
            salary_status: this.selectSalaryData.salaryStatus,
          }

          const result = await this.$XHR.api('cms_update_salary_record', payload)
          this.$func.log('--- update salary data ---')
          this.$func.log(result)

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '儲存成功',
            type: 'success',
            refresh: false,
            redirect: '',
          })

          this.submitLoading = false
          this.openDialog = false
          this.getSalaryList()
        } catch (error) {
          this.$func.log('--- update salary data fail ---')
          this.$func.log(error)

          let msg = '儲存失敗'

          if (error.data === 'admin verification fail') {
            msg = ''
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          } else if (error.data === 'salary record not found') {
            msg = '薪金記錄不存在'
          }

          if (this.$validate.DataValid(msg)) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: msg,
              type: 'error',
              refresh: false,
              redirect: '',
            })
          }

          this.submitLoading = false
        }
      }
    },
    async deleteSalaryRecord(action) {
      this.deleteDialog = false
      if (action) {
        if (!this.submitLoading) {
          this.submitLoading = true

          try {
            const payload = {
              id: parseInt(this.selectSalaryData.id),
              verify_token: this.getAdminToken(),
            }

            const result = await this.$XHR.api('cms_remove_salary_record', payload)
            this.$func.log('--- delete salary data ---')
            this.$func.log(result)

            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: '刪除成功',
              type: 'success',
              refresh: false,
              redirect: '',
            })

            this.submitLoading = false
            this.openDialog = false
            this.getSalaryList()
          } catch (error) {
            this.$func.log('--- delete salary data fail ---')
            this.$func.log(error)

            let msg = '刪除失敗'

            if (error.data === 'admin verification fail') {
              msg = ''
              this.forceLogout()
            } else if (error.data === 'no permission') {
              msg = '沒有權限'
            } else if (error.data === 'salary record not found') {
              msg = '薪金記錄不存在'
            }

            if (this.$validate.DataValid(msg)) {
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: msg,
                type: 'error',
                refresh: false,
                redirect: '',
              })
            }

            this.submitLoading = false
          }
        }
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      if (options.sortBy.length > 0) {
        this.tableSortKey = options.sortBy[0]
      }
      this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

      this.tablePageLimit = options.itemsPerPage
      this.getSalaryList()
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      if (this.$validate.DataValid(this.routeStaffID)) {
        this.staff_id = parseInt(this.routeStaffID)
        await this.getSalaryList()
        this.getStaffList()
      } else {
        this.getStaffList()
        this.$store.dispatch('toggleLoadingPage', false)
      }
    }
  },
}
</script>
